var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code-item" },
    [
      _c("a-form", [
        _c(
          "div",
          { staticClass: "code-item--card" },
          [
            _c(
              "div",
              { staticClass: "content-between" },
              [
                _c("p", { staticClass: "font-bold mb-none" }, [
                  _vm._v(_vm._s(_vm.$t(_vm.item.codeName)))
                ]),
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        placement: "left",
                        title: _vm.$t("Are you sure to delete this code"),
                        "ok-text": _vm.$t("Yes"),
                        "cancel-text": _vm.$t("No")
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.removeCode(_vm.item.key)
                        }
                      }
                    },
                    [
                      _c("a-button", {
                        attrs: { type: "danger", icon: "delete" }
                      })
                    ],
                    1
                  )
                ]
              ],
              2
            ),
            _c("a-divider", { staticClass: "mt-sm mb-md" }),
            _c(
              "a-row",
              {
                staticClass: "mb-md",
                attrs: { gutter: 16, type: "flex", align: "middle" }
              },
              [
                _c("a-col", { attrs: { xs: 4 } }, [
                  _c("p", { staticClass: "mb-none text-right" }, [
                    _vm._v(_vm._s(_vm.$t("Type")))
                  ])
                ]),
                _c(
                  "a-col",
                  { attrs: { xs: 20 } },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { value: _vm.item.naviType, disabled: "" },
                        on: { select: _vm.changeType }
                      },
                      [
                        _c("a-select-option", { attrs: { value: 1 } }, [
                          _vm._v(" " + _vm._s(_vm.$t("Navi")) + " ")
                        ]),
                        _c("a-select-option", { attrs: { value: 0 } }, [
                          _vm._v(" " + _vm._s(_vm.$t("Spot")) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "mb-md", attrs: { gutter: 16 } },
              [
                _c("a-col", { attrs: { xs: 4 } }, [
                  _c("p", { staticClass: "mb-none mt-xs text-right" }, [
                    _vm._v(_vm._s(_vm.$t("Code Name")))
                  ])
                ]),
                _c(
                  "a-col",
                  { attrs: { xs: 18 } },
                  [
                    _c(
                      "a-checkbox-group",
                      {
                        attrs: { value: _vm.languages },
                        on: { change: _vm.changeLanguage }
                      },
                      [
                        _c(
                          "a-row",
                          { attrs: { gutter: [16, 16] } },
                          [
                            _c(
                              "a-col",
                              { attrs: { xs: 12 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      "has-feedback": true,
                                      "validate-status": _vm.validateStatusJpn,
                                      help: _vm.validateStatusJpnMessage
                                    }
                                  },
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        attrs: {
                                          name: "JPN",
                                          value: _vm.name.JPN,
                                          disabled: !_vm.languages.includes(
                                            "JPN"
                                          ),
                                          placeholder: _vm.$t(
                                            "Enter code name in japanese"
                                          )
                                        },
                                        on: { change: _vm.inputChange }
                                      },
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            attrs: {
                                              slot: "addonBefore",
                                              value: "JPN",
                                              disabled:
                                                _vm.disabledCheckboxLang["JPN"]
                                            },
                                            slot: "addonBefore"
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("JPN")) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { xs: 12 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      "has-feedback": true,
                                      "validate-status": _vm.validateStatusEng,
                                      help: _vm.validateStatusEngMessage
                                    }
                                  },
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        attrs: {
                                          name: "ENG",
                                          value: _vm.name.ENG,
                                          disabled: !_vm.languages.includes(
                                            "ENG"
                                          ),
                                          placeholder: _vm.$t(
                                            "Enter code name in english"
                                          )
                                        },
                                        on: { change: _vm.inputChange }
                                      },
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            attrs: {
                                              slot: "addonBefore",
                                              value: "ENG",
                                              disabled:
                                                _vm.disabledCheckboxLang["ENG"]
                                            },
                                            slot: "addonBefore"
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("ENG")) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { xs: 12 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      "has-feedback": true,
                                      "validate-status": _vm.validateStatusChi,
                                      help: _vm.validateStatusChiMessage
                                    }
                                  },
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        attrs: {
                                          name: "CHI",
                                          value: _vm.name.CHI,
                                          disabled: !_vm.languages.includes(
                                            "CHI"
                                          ),
                                          placeholder: _vm.$t(
                                            "Enter code name in chinese"
                                          )
                                        },
                                        on: { change: _vm.inputChange }
                                      },
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            attrs: {
                                              slot: "addonBefore",
                                              value: "CHI",
                                              disabled:
                                                _vm.disabledCheckboxLang["CHI"]
                                            },
                                            slot: "addonBefore"
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("CHI")) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { xs: 12 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      "has-feedback": true,
                                      "validate-status": _vm.validateStatusKor,
                                      help: _vm.validateStatusKorMessage
                                    }
                                  },
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        attrs: {
                                          name: "KOR",
                                          value: _vm.name.KOR,
                                          disabled: !_vm.languages.includes(
                                            "KOR"
                                          ),
                                          placeholder: _vm.$t(
                                            "Enter code name in korean"
                                          )
                                        },
                                        on: { change: _vm.inputChange }
                                      },
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            attrs: {
                                              slot: "addonBefore",
                                              value: "KOR",
                                              disabled:
                                                _vm.disabledCheckboxLang["KOR"]
                                            },
                                            slot: "addonBefore"
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("KOR")) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-row",
              { staticClass: "my-md" },
              [_c("preview-code", { attrs: { points: _vm.points } })],
              1
            ),
            _c(
              "a-row",
              [
                _vm.item.naviType === 1
                  ? _c("code-item-navi", {
                      attrs: {
                        languages: _vm.newLanguages,
                        points: _vm.points,
                        codeStatus: _vm.codeStatus
                      },
                      on: { setPoints: _vm.setPoints }
                    })
                  : _c("code-item-faci", {
                      attrs: {
                        languages: _vm.newLanguages,
                        points: _vm.points,
                        codeStatus: _vm.codeStatus
                      },
                      on: { setPoints: _vm.setPoints }
                    })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }