//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { parseCodes } from "../parse-data-client";
import { mapGetters } from 'vuex';
export default {
  props: {
    visible: { type: Boolean, required: true, default: () => false },
    title: { type: String, default: () => '' },
    firstTime: { type: Boolean, default: () => true },
    isCreated: { type: Boolean, default: () => false }
  },
  data() {
    return {
      options: [],
      value: [],
      naviType: 1,
      loading: false,
      isSelect: false,
      currentTitle: this.title
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters('user', ['userInfo'])
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await this.$s.api.page.getCodeDrafts(this.userInfo.company_id);
        const newOptions = data.reduce((acc, item) => {
          return acc.concat({ label: item.title, value: item.point_list, key: item.id });
        }, []);
        this.options = newOptions;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close');
    },
    selectCodes() {
      this.isSelect = true;

      setTimeout(() => {
        this.$emit('setPointsImport', {
          type: this.naviType,
          points: this.value,
          title: this.currentTitle
        });
      }, 100);
    },
    goToImportFile() {
      this.$emit('goToImportFile');
    }
  }
};
