export interface NaviPointCode {
  key: number,
  ENG: string,
  JPN: string,
  CHI: string,
  KOR: string,
  LAT: number | string,
  LONG: number | string,
  INFO_ENG: string,
  INFO_JPN: string,
  INFO_CHI: string,
  INFO_KOR: string,
  editable: boolean
}

export interface FaciPointCode {
  key: number,
  ENG: string,
  JPN: string,
  KOR: string,
  CHI: string,
  LAT: number | string,
  LONG: number | string,
  INFO_ENG: string,
  INFO_JPN: string,
  INFO_CHI: string,
  INFO_KOR: string,
  CATEGORIES: string[],
  editable: boolean
}

export const defaultLanguages = ["ENG", "JPN", "CHI", "KOR"]
export const defaultInformation = [
  "INFO_ENG",
  "INFO_JPN",
  "INFO_CHI",
  "INFO_KOR"
]

export const initNaviPoint: NaviPointCode = {
  key: 1,
  ENG: "",
  JPN: "",
  KOR: "",
  CHI: "",
  LAT: "",
  LONG: "",
  INFO_ENG: "",
  INFO_JPN: "",
  INFO_KOR: "",
  INFO_CHI: "",
  editable: true,
}

export const initFaciPoint: FaciPointCode = {
  key: 1,
  ENG: "",
  JPN: "",
  KOR: "",
  CHI: "",
  LAT: "",
  LONG: "",
  CATEGORIES: [],
  INFO_ENG: "",
  INFO_JPN: "",
  INFO_CHI: "",
  INFO_KOR: "",
  editable: true,
}

export const storeCategories = {
  ja: [
    { id: 1, name: '公共機関' },
    { id: 2, name: '宿泊施設' },
    { id: 3, name: '交通機関' },
    { id: 4, name: '神社仏閣' },
    { id: 5, name: '飲食店' },
    { id: 6, name: 'ショッピング' },
    { id: 7, name: '医療機関' },
    { id: 8, name: '観光名所' },
    { id: 9, name: '役所' },
    { id: 10, name: '学校' },
    { id: 11, name: 'コンビニエンスストア' },
    { id: 12, name: 'ガソリンスタンド' },
    { id: 13, name: 'ポスト' },
    { id: 14, name: '美術館・博物館' },
    { id: 15, name: '名所・旧跡' },
    { id: 16, name: '自然・風景' },
    { id: 17, name: '工房・体験施設' },
    { id: 18, name: '教会' },
    { id: 201, name: '避難場所' },
    { id: 202, name: '避難所' },
    { id: 203, name: '公衆電話' },
    { id: 204, name: '災害用公衆電話' },
    { id: 205, name: '給水所（給水拠点）' },
    { id: 206, name: '公共トイレ' },
    { id: 207, name: '帰宅困難者滞在施設' },
    { id: 208, name: '一時避難場所' },
    { id: 209, name: '福祉避難所' },
    { id: 210, name: 'AED' },
    { id: 211, name: '津波避難施設' },
    { id: 212, name: '警察署（交番、派出所）' },
    { id: 213, name: '消防署（屯所）' },
    { id: 214, name: '病院' },
    { id: 215, name: '災害時救急病院' },
    { id: 216, name: '災害時用ヘリポート' },
    { id: 217, name: '消火栓' },
    { id: 301, name: '競技場' },
  ],
  en: [
    { id: 1, name: 'Public institution' },
    { id: 2, name: 'Accommodations' },
    { id: 3, name: 'Transportation' },
    { id: 4, name: 'Shrines and temples' },
    { id: 5, name: 'Restaurants' },
    { id: 6, name: 'Shopping' },
    { id: 7, name: 'Mmedical facility' },
    { id: 8, name: 'Sightseeing spot' },
    { id: 9, name: 'government office' },
    { id: 10, name: 'school' },
    { id: 11, name: 'convenience store' },
    { id: 12, name: 'gas station' },
    { id: 13, name: 'postbox' },
    { id: 14, name: 'museum' },
    { id: 15, name: 'scenic sites' },
    { id: 16, name: 'natural scenery' },
    { id: 17, name: 'workshop' },
    { id: 18, name: 'church' },
    { id: 201, name: 'Evacuation shelter' },
    { id: 202, name: 'shelter' },
    { id: 203, name: 'Public phone' },
    { id: 204, name: 'Special public phone' },
    { id: 205, name: 'water station' },
    { id: 206, name: 'public restroom' },
    { id: 207, name: 'Temporary stay facilities' },
    { id: 208, name: 'Short-term Evacuation Shelters' },
    { id: 209, name: 'Social Welfare Institution Evacuation Shelters' },
    { id: 210, name: 'AED' },
    { id: 211, name: 'TSUNAMI Evacuation facilitiy' },
    { id: 212, name: 'police station' },
    { id: 213, name: 'fire station' },
    { id: 214, name: 'hospital' },
    { id: 215, name: 'Disaster emergency hospital' },
    { id: 216, name: 'Emergency Helicopter Landing Facilities' },
    { id: 217, name: 'fire hydrant' },
    { id: 301, name: 'Stadium' },
  ]
}

export const codeDefault = {
  key: 1,
  codeName: "Code #1",
  naviType: 1,
  languages: ["ENG"],
  name: {
    ENG: "",
    JPN: "",
    KOR: "",
    CHI: ""
  },
  points: []
};