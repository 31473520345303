//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultLanguages } from './types';

export default {
  components: {
    CodeItemNavi: () => import('./CodeItemNavi'),
    CodeItemFaci: () => import('./CodeItemFaci'),
    PreviewCode: () => import('./modals/PreviewCode')
  },
  props: {
    item: {
      required: true
    },
    codeStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      naviType: 1,
      languages: ['JPN'],
      points: [],
      name: {
        ENG: '',
        JPN: '',
        CHI: '',
        KOR: ''
      }
    };
  },
  created() {
    this.initialData();
  },
  filters: {
    messages(status) {
      if (status === 'error') return 'Code name is required.';
      return '';
    }
  },
  computed: {
    validateStatusEng() {
      return this.languages?.includes('ENG') ? (this.name.ENG ? 'success' : 'error') : '';
    },
    validateStatusJpn() {
      return this.languages?.includes('JPN') ? (this.name.JPN ? 'success' : 'error') : '';
    },
    validateStatusKor() {
      return this.languages?.includes('KOR') ? (this.name.KOR ? 'success' : 'error') : '';
    },
    validateStatusChi() {
      return this.languages?.includes('CHI') ? (this.name.CHI ? 'success' : 'error') : '';
    },
    validateStatusEngMessage() {
      return this.$t(this.$options.filters.messages(this.validateStatusEng));
    },
    validateStatusJpnMessage() {
      return this.$t(this.$options.filters.messages(this.validateStatusJpn));
    },
    validateStatusKorMessage() {
      return this.$t(this.$options.filters.messages(this.validateStatusKor));
    },
    validateStatusChiMessage() {
      return this.$t(this.$options.filters.messages(this.validateStatusChi));
    },
    disabledCheckboxLang() {
      const checkLangLength = this.languages.length === 1;
      const mapping = {
        JPN: this.languages.includes('JPN') && checkLangLength,
        ENG: this.languages.includes('ENG') && checkLangLength,
        KOR: this.languages.includes('KOR') && checkLangLength,
        CHI: this.languages.includes('CHI') && checkLangLength
      };
      return mapping;
    },
    newLanguages() {
      const newLangs = this.languages.reduce((acc, item) => {
        acc = { ...acc, [item]: item };
        return acc;
      }, {});
      return [
        newLangs?.JPN && 'JPN',
        newLangs?.ENG && 'ENG',
        newLangs?.CHI && 'CHI',
        newLangs?.KOR && 'KOR'
      ].filter(item => item !== undefined);
    }
  },
  methods: {
    initialData() {
      this.naviType = this.item?.naviType;
      this.languages = this.item?.languages;
      this.name = this.item?.name;
      this.points = this.item?.points;
    },
    removeCode(codeKey) {
      this.$emit('remove', codeKey);
    },
    inputChange({ target }) {
      const names = {
        codeKey: this.item.key,
        key: target.name,
        value: target.value
      };
      this.name = { ...this.name, [target.name]: target.value };
      this.$emit('setName', names);
    },
    changeLanguage(languages) {
      this.languages = languages;
      this.$emit('setLanguages', { codeKey: this.item.key, languages });

      const newPoints = this.item.points;
      defaultLanguages.forEach(lang => {
        if (!languages?.includes(lang)) {
          newPoints.map(item => ((item[lang] = ''), (item[`INFO_${lang}`] = '')));
        }
      });
      this.$emit('setPoints', { codeKey: this.item.key, newPoints });
    },
    changeType(value) {
      this.$emit('setType', { codeKey: this.item.key, value });
      this.points = [];
    },
    setPoints(newPoints) {
      this.points = [...newPoints];
      this.$emit('setPoints', { codeKey: this.item.key, newPoints });
    }
  }
};
