const combindCodeName = (languages, name) => {
  const codeName = languages.reduce((arr, lang) => {
    return arr.concat({ lang: lang, text: name[lang] });
  }, []);
  return codeName;
};

export const combindPoints = (points, languages, type) => {
  const prefix = 'INFO_';
  return points.map(item => {
    const name = languages.reduce((arr, lang) => {
      return item[lang] !== '' && arr.concat({ lang: lang, text: item[lang] });
    }, []);
    const information = languages.reduce((arr, lang) => {
      if (item[`${prefix}${lang}`]) {
        return arr.concat({ lang: lang, text: item[`${prefix}${lang}`] });
      }
    }, []);
    let point = {
      name: name,
      information: information || [],
      lat: item.LAT.toString(),
      lon: item.LONG.toString()
    };
    if (type === 'Faci') {
      point = { ...point, categories: item.CATEGORIES.join('|') };
    }
    return point;
  });
};
export const combindCode = codes => {
  return codes.map(item => {
    const points =
      item.naviType === 1
        ? combindPoints(item.points, item.languages, 'Navi')
        : combindPoints(item.points, item.languages, 'Faci');
    return {
      id: item.key.toString(),
      navi_type: item.naviType.toString(),
      name: combindCodeName(item.languages, item.name),
      points: points
    };
  });
};
